import * as React from "react"
import ContentPageLayout from "../../components/ContentPageLayout"
import Seo from "../../components/seo"
// import { StaticImage } from "gatsby-plugin-image";
// import { getLegalPages } from "../../api/content";
import ReactMarkdown from "react-markdown"
import TextField from "@material-ui/core/TextField"
import { IconButton, InputAdornment } from "@material-ui/core"
import SCButton from "../../components/buttons/SCButton"
import { BrightnessAutoRounded } from "@material-ui/icons"
import { applyCode } from "../../api/content"
import firebase from "firebase"
import { firebaseInit } from "../../config"
import { navigate } from "gatsby-link"

const IndexPage = () => {
  const [code, setCode] = React.useState("")
  const [userToken, setUserToken] = React.useState(null)
  const [appliedCode, setAppliedCode] = React.useState(false)
  const [error, setError] = React.useState("")

  const sendCode = () => {
    resetError()

    if (code !== "") {
      applyCode(userToken, code)
        .then(response => {
          if (response.estatus === "Error") {
            // Si estatus es Error, mostramos el mensaje de error
            setError(
              response.message || "Ocurrió un error al aplicar el código"
            )
          } else {
            // Si estatus es Success, aplicamos el código
            setAppliedCode(true)
          }
        })
        .catch(error => {
          if (error.errorCode === 1004) {
            // Si el errorCode es 1004, redirigimos a la ruta correspondiente
            navigate(`/settings/status?code=${error.codigo}&token=${userToken}`)
          } else {
            // Si estatus es Error, mostramos el mensaje de error
            setError(
              error.message || "Ocurrió un error al aplicar el código"
            )
          }
        })
    } else {
      setError("Ingresa tu código válido")
    }
  };

  React.useEffect(() => {
    firebaseInit(firebase);
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken(true).then(token => {
          setUserToken(token);
        });
      } else setUserToken(null);
    });
  }, []);

  const resetError = () => setTimeout(() => setError(""), 10000)

  return (
    <ContentPageLayout>
      <Seo title="Código Promocional" />
      <div className="flex w-full justify-center">
        {!appliedCode ? (
          <div className="flex text-center h-screen flex-col w-80 justify-center items-center">
            <h1 className="text-3xl md:mb-14 md:text-3xl">Ingresa tu código</h1>
            <hr className="w-full" />
            <p className="font-bold">Términos y condiciones de promoción</p>
            <TextField
              className="w-64"
              style={{ marginTop: "5px", marginBottom: "5px" }}
              type={"text"}
              onChange={({ target }) => setCode(target.value)}
              value={code}
              placeholder="Ingresa aquí tu código"
              error={!!error}
              helperText={error}
            />
            <div className="w-64 mt-8">
              <SCButton
                style={{
                  marginTop: "8px",
                  backgroundColor: error ? "red" : "initial",
                }}
                onClick={sendCode}
              >
                <b>Validar y aplicar</b>
              </SCButton>
            </div>
          </div>
        ) : (
          <div className="flex text-center flex-col w-80 justify-center items-center h-screen">
            <p className="font-bold">
              Tu código se ha aplicado satisfactoriamente, ahora podrás
              disfrutar los beneficios que SHIFT te brinda
            </p>
            <div className="w-64 mt-12">
              <SCButton
                onClick={() => {
                  navigate(`/`)
                }}
              >
                <b>Ir a contenido</b>
              </SCButton>
            </div>
          </div>
        )}
      </div>
    </ContentPageLayout>
  );
};

export default IndexPage
